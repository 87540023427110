<style>
#home canvas.max-w100 { 
    max-width: 100%; 
} 

#home .disabled-feature {
    cursor: not-allowed;
}

#topEXPWarning .alert-dismissible .btn-close {
    padding: 0.3rem 0.3rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  }

#topEXPWarning .alert {
    padding: 0.3rem 1rem;
    text-align: center;
    border-radius: 0rem;
    vertical-align: middle;
    margin-bottom: 0px;
}

#topEXPWarning .p {
    font-size: 16px;
    font-weight: 700;
}
input#short-link-product {
    pointer-events: none;
}

</style>
<template>
  <div id="home" style="padding: 0 1.5rem;">
    <div class="fixed-top" id="topEXPWarning">
        <div
        class="alert alert-dismissible text-white bg-danger"
        v-if="textLicenseExp(Global.brokerDetails.licenseExpDate) && Global.brokerDetails.accountSource !== 'Affiliate'"
        >
            <p class="p mb-0">{{$t('warningLicenseExpire')}}</p>
            <button type="button" class="btn-close my-1 mx-auto" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
    <Consol></Consol>
    
    <div class="modal fade" id="personalDocumentsModal" tabindex="-1" aria-labelledby="personalDocumentsLabel" aria-hidden="true">
        <div role="document" class="modal-dialog modal-lg">
            <div  class="modal-content ">
                <div  class="modal-header">
                    <h4  class="modal-title" id="personalDocumentsLabel">{{$t('personalDocsHeader')}}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <form  class="row mt-2 py-2 bg-white rounded shadow px-2">
                            <div class="col-auto mx-auto my-2">
                                <div class="input-group">
                                <span class="input-group-text"
                                    ><i class="fa fa-calendar text-primary me-1" aria-hidden="true"></i>
                                    {{$t('dateLabel')}}</span>
                                    <div class="flex justify-center items-center">
                                        <input class="form-control" type ="month"  v-model="monthYearDoc" min="2022-01" :max="monthYearDocMax"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto mx-auto my-2">
                                <button class="btn btn-sm bg-gradient-primary mx-auto " type="button" v-on:click="loadPersonalDocHander(true)"><i class="fa fa-download fa-5x" aria-hidden="true"></i> {{$t('downloadDocButton')}}</button>
                            </div>
                        </form >
                        <form  class="row mt-2 py-2 bg-white rounded shadow px-2">
                            <div class="text-center" v-if="persionDocsLoading">
                                <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true"></span>
                            </div>
                            <div v-else>
                                <div class = "row">
                                    <h3>{{monthYearDocMMYYYY}}</h3>
                                </div>
                                <div class = "row" v-if = "personalDocs.length === 0">
                                    <h4 class="text-center" >{{$t('emptyDocs')}}</h4>
                                </div>
                                <div class="row my-2" v-for="item in personalDocs" :key="item.s3Key">
                                    <span v-if="item.isPdf"><a :href="item.fileUrl" target="_blank"><i class="fa fa-file-pdf fa-3x" aria-hidden="true"></i> {{decodeURI(item.documentName)}}</a></span>
                                    <span v-else><a :href="item.fileUrl" :download = "decodeURI(item.documentName)"><i class="fa fa-file-archive-o fa-3x" aria-hidden="true"></i> {{decodeURI(item.documentName)}}</a></span>
                                </div>
                            </div>
                        </form >
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary text-white bg-gradient-primary" data-bs-dismiss="modal">{{$t('closeDownloadDocButton')}}</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-8 col-sm-6 mb-xl-0 mb-4">
            <div class="row"> 
                <div class="col-12">     
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-lg-5 mb-2">
                                    <div class=" h-100">
                                        <h4 class="font-weight-bolder mt-2 mb-1">{{$t('greeting')}}</h4>
                                        <p class="text-sm mb-3 text-capitalize font-weight-bold">{{ $d(new Date(), 'long') }}</p>
                                        <img :src="publicPath + 'img/hello2dash4.png'" alt="Hello" class="max-w100" />
                                        <div class="mt-3" :class="{'wrapper' : Global.authority === 'ADMIN'}">
                                            <router-link :to="Global.brokerDetails.accountSource !== 'Affiliate' ? '/dashboard' : '/dashboard-aff'">
                                                <button class="btn bg-gradient-info" type="button" :disabled="Global.authority === 'ADMIN'">{{$t('viewMyDashboard')}}</button>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>      
                                <div class="col-12 col-lg-7 mb-2 ">
                                    <div class="card p-1">
                                        <div class="overflow-hidden position-relative border-radius-lg r h-100 ">
                                        <span class="mask bg-gradient-secondary2"></span>
                                        <div class="card-body position-relative z-index-1 h-100 px-3 pt-3 pb-3 bg2profile d-flex flex-column">
                                            <h6 class="text-white text-xs font-weight-bolder mb-3">{{$t('yourProfile')}}</h6>
                                            <p v-if="Global.brokerDetails.accountSource !== 'Traditional broker'" class="text-white mb-2"><i class="fa fa-solid fa-passport fa-fw text-white-50" aria-hidden="true"></i> {{Global.brokerDetails.gaNumber}}</p>
                                            <p class="text-white mb-2"><i class="fa fa-user fa-fw text-white-50" aria-hidden="true"></i> {{displayBrokerName()}}</p>
                                            <p class="text-white mb-2"><i class="fa fa-mobile fa-fw text-white-50" aria-hidden="true"></i> {{showNullWithDash(Global.brokerDetails.phone)}}</p>
                                            <p class="text-white mb-2"><i class="fa fa-envelope fa-fw text-white-50" aria-hidden="true"></i> {{Global.brokerDetails.intermediaryReptEmail}}</p>
                                            <p class="text-white mb-1">
                                                <i
                                                class="fa fa-university fa-fw text-white-50"
                                                aria-hidden="true"
                                                ></i>
                                                 {{showNullWithDash(Global.brokerDetails.bankName)}}
                                            </p>
                                            <p class="text-white mb-2 ms-4">
                                                 {{showNullWithDash(Global.brokerDetails.bankAccount)}}
                                            </p>
                                            <p class="text-white mb-1">
                                                <i
                                                class="fa fa-id-card fa-fw text-white-50"
                                                aria-hidden="true"
                                                ></i>
                                                {{showNullWithDash(Global.brokerDetails.licenseNumber)}} 
                                            </p>
                                            <p class="text-white mb-2  ms-4" v-if="licenseExpDate">
                                                {{$t('expProfile')}}  {{showNullWithDash($d(convertBEtoCE(licenseExpDate), {day: 'numeric', month: 'long', year: '2-digit'}))}} 
                                            </p>
                                            <p class="text-white mb-2  ms-4" v-else>
                                                {{$t('expProfile')}}  {{showNullWithDash(licenseExpDate)}} 
                                            </p>
                                            <p class="text-white mb-1"><i class="fa fa-map-marker fa-fw text-white-50" aria-hidden="true"></i>
                                                {{$t('address')}}
                                            </p>
                                            <p class="text-white mb-2 ms-4 w-70">
                                                {{showNullWithDash(Global.brokerDetails.address)}}
                                            </p>
                                            <div class="mt-5" v-if="false">
                                                <a class="text-white text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;">
                                                    Edit
                                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>    
                            </div>   
                        </div>
                    </div>
                </div>    
            </div>	
            <div class="row mt-4" v-if="Global.brokerDetails.accountSource !== 'Affiliate'"> 
                <div class="col-12 col-md-6 col-lg-6 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('getNewQuotation')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/products3.png'" alt="products" class="max-w100" />
                                </div>
                                <span class="mt-auto" v-bind:class="{'wrapper' : isNullOrEmpty(Global.brokerDetails.licenseNumber)}">
                                    <router-link to="/new-quote" class="text-body text-sm font-weight-bold mb-0 icon-move-right">
                                        {{$t('next')}}<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                    </router-link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-6 mb-lg-0 mb-4">
                    <div class="card h-100 p-0 ">
                        <div class="card-boday p-3 h-1000 d-none">
                            <div class="d-flex flex-column h-100">
                                <!--p class="mb-0 pt-2 text-normal">Your monthly</p-->
                                <h5 class="font-weight-bolder mb-3">My monthly targets</h5>
                                <div class="mb-3 nav-link disabled">
                                    <p class="mb-1 mt-0 font-weight-bolder">Car insurace<span class="badge bg-gradient-secondary ms-1">0</span></p>
                                    <p class="mb-1 mt-0 font-weight-bolder">Motorbike insurace<span class="badge bg-gradient-secondary ms-1">0</span></p>
                                    <p class="mb-1 mt-0 font-weight-bolder">Persoanl accident insurace<span class="badge bg-gradient-secondary ms-1">0</span></p>
                                    <p class="mb-1 mt-0 font-weight-bolder">Cancer insurace<span class="badge bg-gradient-secondary ms-1">0</span></p>
                                    <p class="mb-1 mt-0 font-weight-bolder">COVID-19 insurace<span class="badge bg-gradient-secondary ms-1">0</span></p>
                                </div>
                                <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-3 disabled-feature" href="javascript:;">
                                    Setting <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </a>
                            </div> 
                        </div>	 

                        <div class="card-boday p-3 h-1000">
                            <div class="d-flex flex-column h-100">   
                            <h5 class="font-weight-bolder mb-3">{{$t('tipsAndGuideline')}}</h5>
                                <div class="mb-3">
                                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#menu2vdo"><img :src="publicPath + 'img/vdo.png'" alt="VDO" class="max-w100"/></a>
                                </div>
                                <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-3" href="javascript:;" data-bs-toggle="modal" data-bs-target="#menu2vdo">
                                    {{$t('playVDO')}} <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>    
                                </a>
                            </div>

                        </div>	  
                    </div>
                </div>
            </div>	
            <div class="row mt-4" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('opportunityReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/opp2state2.png'" alt="Opportunity" class="max-w100" />
                                </div>

                                <router-link to="/opportunity-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>           
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('renewalReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/renew2state.png'" alt="Renewal" class="max-w100" />
                                </div>
                                <router-link to="/renewal-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('inspectionReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/inspect2state.png'" alt="Inspection" class="max-w100" />
                                </div>
                                <router-link to="/inspection-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div class="row mt-4" v-if="Global.brokerDetails.accountSource !== 'Affiliate'">
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('activepolicyReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/insurance-report.png'" alt="Opportunity" class="max-w100" />
                                </div>

                                <router-link to="/active-policy-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('paymentFailureReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/payment-report.png'" alt="Opportunity" class="max-w100" />
                                </div>

                                <router-link to="/payment-failure-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('cancellationReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/cancel-report.png'" alt="Opportunity" class="max-w100" />
                                </div>

                                <router-link to="/cancellation-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>	   
            <div class="row mt-4" v-if="Global.brokerDetails.accountSource === 'Affiliate'">
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-boday p-3 h-1000">
                            <div class="d-flex flex-column h-100">   
                            <h5 class="font-weight-bolder mb-3">{{$t('tipsAndGuideline')}}</h5>
                                <div class="mb-3">
                                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#menu2vdo"><img :src="publicPath + 'img/vdo.png'" alt="VDO" class="max-w100"/></a>
                                </div>
                                <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-3" href="javascript:;" data-bs-toggle="modal" data-bs-target="#menu2vdo">
                                    {{$t('playVDO')}} <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>    
                                </a>
                            </div>

                        </div>	
                    </div>
                </div>           
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('oppReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/opp2state2.png'" alt="Opportunity" class="max-w100" />
                                </div>

                                <router-link to="/opportunity-aff-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-4 mb-lg-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div class="d-flex flex-column h-100">
                                <h5 class="font-weight-bolder">{{$t('cancellationAffiliateReport')}}</h5>
                                <div class="text-left mb-3">
                                    <img :src="publicPath + 'img/renew2state.png'" alt="Renewal" class="max-w100" />
                                </div>
                                <router-link to="/cancellation-aff-report" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                                    {{$t('viewReport')}}
                                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>	  
        </div>         
        <div class="col-xl-4 col-sm-6">  
            <div class="card bg-white border-radius-bottom-start-md-0 border-radius-bottom-end-md-0 mb-2">
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="numbers">
                                <!-- <p class="text-sm mb-0 text-capitalize font-weight-bold">your</p> -->
                                <h5 class="font-weight-bolder mb-0">
                                {{$t('toolsAndFeature')}}                    
                                </h5>
                            </div>
                            <div class="row" v-if="Global.brokerDetails.accountSource === 'Ground agent'">
                                 <div class="col-12">
                                    <button class="btn btn-sm bg-gradient-primary mx-auto my-2" type="button" data-bs-toggle="modal" data-bs-target="#personalDocumentsModal" v-on:click="loadPersonalDocHander(false)">{{$t('myPersonalDoc')}}</button>
                                </div> 
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 text-end">
                            <div class="icon icon-shape bg-gradient-secondary shadow text-center border-radius-2xl">
                                <i class="fa fa-qrcode text-lg opacity-10 mt-1" aria-hidden="true"></i>
                            </div>
                            <div class="icon icon-shape bg-gradient-secondary shadow text-center me-1 border-radius-2xl">
                                <i class="fa fa-share-alt text-lg opacity-10 mt-1" aria-hidden="true"></i>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border-radius-top-end-md-0 border-radius-top-start-md-0">
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-12 mb-5" id="customer-short-link-product">
                            <h6 class="text-primary mb-1 font-weight-bold">{{$t('yourLink')}}</h6>
                            <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!Global.productShortlink.selected && !Global.productShortlink.isError"></span>                           
                            <p id="error-message" class="card-text mt-2 error-message" v-else-if="Global.productShortlink.isError">{{$t("serviceNotAvailable")}}</p>
                            <template v-else>
                                <select class="form-select mx-auto my-2" @change="productChanged($event)" aria-label="Product" data-selenium-name="product-shortlink-ddl">                                    
                                    <option v-for="key in Global.productShortlink.productList" 
                                        v-bind:value="key.product"
                                        v-bind:key="key.product" 
                                        v-bind:label="$t('product.'+key.product)"
                                        data-selenium-name="product-shortlink-option"
                                        :data-selenium-value="key.product">{{$t('product.'+key.product)}}</option>
                                </select>                                   
                                <input :tabindex="-1" type="text" class="form-control" id="short-link-product" :value="Global.productShortlink.selected">
                                <button class="btn btn-sm bg-gradient-primary mx-auto my-2" type="button" v-on:click="copyLinkToClipBoard('short-link-product')">{{$t('copyLink')}}</button>
                            </template>                        
                        </div>
                        <div class="col-12" id="customer-qrcode">
                            <h6 class="text-primary mb-1 font-weight-bold">{{$t('yourQRcode')}}</h6>
                            <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="isNull(Global.customerQRCode) && !isQrcodeError"></span>
                            <p id="error-message" class="card-text mt-2 error-message" v-else-if="isQrcodeError">{{$t("serviceNotAvailable")}}</p>
                            <template v-else>
                                <canvas id="qrCodeCanvas" class="max-w100 border-radius-lg" v-show="Global.customerQRCode"></canvas>
                                <button class="btn btn-sm bg-gradient-primary mx-auto my-2 position-absolute end-2" type="button" v-on:click="downlaodMarketeerImage()" v-if="Global.customerQRCode">{{$t('download')}}</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import dayjs from "@/dayjs.js";
import Consol from "@/components/Consol";
export default {
    mixins: [mixin],
    name: "Home",
    components: { Consol },
    data: function () {
        return {
            publicPath: process.env.BASE_URL,
            isShortLinkError: false,
            isQrcodeError: false,
            currentDate: dayjs().format("dddd D, MMM YYYY"),
            licenseExpDate: Global.brokerDetails.licenseExpDate,
            monthYearDoc: dayjs().subtract(1, 'month').format("YYYY-MM"),
            monthYearDocMax: dayjs().subtract(1, 'month').format("YYYY-MM"),
            personalDocs:[],
            monthYearDocMMYYYY: dayjs().subtract(1, 'month').format("M_YYYY"),
            persionDocsLoading:true
        };
    },
    created() {   
        this.getShortLinkAndQRCode();  
        this.getProductShortLinks();
        this.eventTracking('mainMenu', 'Menu_Home', 'Portal');
        
    },
    methods: {
        productChanged:  function(event){
            Global.productShortlink.selected = (Global.productShortlink.productList.find(x => x.product === event.target.value))?.shortlink || '';
        },
        loadImages: function(sources, callback) {
            var images = {};
            var loadedImages = 0;
            var numImages = Object.keys(sources).length;

            for(var src in sources) {
                images[src] = new Image();
                images[src].onload = function() {
                if(++loadedImages >= numImages) {
                    callback(images);
                }
                };
                images[src].src = sources[src];
            }
        },
        drawMarketeerImage: function(){
            var canvas = document.getElementById('qrCodeCanvas');

            if( !canvas ){
                return false;
            }

            var ctx = canvas.getContext('2d');

            var sources = {
                image1: this.publicPath + 'img/marketeer-' + Global.locale + '.jpg',
                image2: Global.customerQRCode
            };

            this.loadImages(sources, function(images) {
                canvas.width = images.image1.naturalWidth;
                canvas.height = images.image1.naturalHeight;
                ctx.drawImage(images.image1, 0, 0);
                ctx.drawImage(images.image2, 220, 990, 145, 145);
            });
        },
        downlaodMarketeerImage: function(){
            var canvas = document.getElementById("qrCodeCanvas");
            if( !canvas ){
                return false;
            }

            var image = canvas.toDataURL("image/jpg", 1.0).replace("image/jpg", "image/octet-stream");
            var link = document.createElement('a');
            link.download =  "QRCode" + Global.locale.toUpperCase() + this.getCurrentDateNoDash() + ".jpg";
            link.href = image;
            link.click();
        },
        getShortLinkAndQRCode: async function () {
            var vm = this;
            if (!Global.customerShortLink) {
                await vm.getCustomerShortLink();
                if (!Global.customerShortLink) {
                    vm.isShortLinkError = true;
                    vm.isQrcodeError = true;
                } else {
                    vm.isShortLinkError = false;
                    if (!Global.customerQRCode) {
                        await vm.getCustomerShortLinkQRCode();
                        if(Global.customerQRCode){
                            vm.isQrcodeError = false
                            vm.drawMarketeerImage();
                        }else{
                            vm.isQrcodeError = true;
                        }
                    }
                }
            }else {
                setTimeout(function(){
                    vm.drawMarketeerImage();
                }, 50);
            }
        },
        maskEmail: function(email) {
            function mask(str, isMaskLastChar) {
                var strLen = str.length;
                if (isMaskLastChar) 
                    return str.substr(0, 1) + str.substr(1, strLen - 2).replace(/\w/g, '*') + str.substr(-1,1);
                else
                    return str.substr(0, 1) + str.substr(1, strLen).replace(/\w/g, '*');
            }
            return email?.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, function (m, p1, p2, p3) {   
                // console.log(m);
                // console.log(p1);
                // console.log(p2);
                // console.log(p3);
                return mask(p1, false) + '@' + mask(p2, true) + p3;
            });
        },
        displayBrokerName: function(){
            var fullName = [Global.brokerDetails.firstname, Global.brokerDetails.lastname].filter(Boolean).join(' ');
            return this.showNullWithDash(fullName);
        },
        textLicenseExp: function(expDate) {
            var vm = this;
            if (vm.isNotNullAndNotUndefined(expDate))
            {
                if(parseInt(dayjs(expDate).year()) > 2500){
                    expDate = dayjs(expDate).subtract(543, 'year');
                }
                else{
                    expDate = dayjs(expDate);
                }
                var now = dayjs();
                var days = parseInt(expDate.diff(now, 'd')+1);
                const res = Global.brokerDetails.licenseExpRemainDaysAlert.includes(days)? days : false;
                return res;
            }else{
            //NotValid CASE
                return false;
            }
        },
        convertBEtoCE: function(date){
            var dateObj = dayjs(date).isValid() ?   dayjs(date) : false;
            if(dateObj)
            {
                var yearD = parseInt(dateObj.diff(dayjs(), 'y'));
                if(yearD > 500)
                {
                    dateObj = dayjs(dateObj).subtract(543, 'year');
                    return dateObj.format("YYYY-MM-DD");
                }
                else
                {
                    return dateObj.format("YYYY-MM-DD");
                }
            }
            else
            {
                return null;
            }
        },
        loadPersonalDocs:async function(val, isZipped = false){
            let vm = this;
            let docNames = [];
            let splitVal = val.split("-");
            let tmpMonth = splitVal[1];
            if(tmpMonth[0] === '0'){
                tmpMonth = splitVal[1][1];
            }
            if(!isZipped){
                vm.persionDocsLoading = true;
                docNames.push(`${tmpMonth}_${splitVal[0]}`);
            let result = await this.getPersonalDocs(docNames, isZipped);
                vm.personalDocs = [...result];
                vm.monthYearDocMMYYYY = `${tmpMonth}_${splitVal[0]}`;
            }else{
                let maxMonth = 12;
                if(parseInt(splitVal[0],10) === dayjs().get('year')){
                    maxMonth = dayjs().get('month');
                }
                for(let i = 1 ; i <= maxMonth; i++){
                    docNames.push(`${i}_${splitVal[0]}`);
                }
                if(maxMonth === 0){
                    docNames.push(`0_${splitVal[0]}`);
                }
                let result = await this.getPersonalDocs(docNames, isZipped);
                await vm.downloadPersonalDocsZip(result, splitVal[0]);
            }
            vm.persionDocsLoading = false;
        },
        downloadPersonalDocsZip: async function (responseData, fileName){
            let vm = this;
            if(responseData[0]?.data && responseData[0]?.data?.length > 0){
                let binaryData = [];
                binaryData.push(Buffer.from(responseData[0].data));
                const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));
                const isIOS = (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Macintosh/i));
                if(isIOS){
                    vm.persionDocsLoading = true;
                    vm.personalDocs = [{
                                documentName:`${new Date().getTime()}_${fileName}.zip`,
                                s3Key:'',
                                fileUrl:url,
                                isPdf:false,
                                lastmodified: dayjs().format()
                    }];
                    vm.monthYearDocMMYYYY = fileName;
                    vm.persionDocsLoading = false;
                }else{
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${new Date().getTime()}_${fileName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }

            }else{
                vm.openErrorModal(vm.$t('loadPersonalDocs.error.title'), vm.$t('loadPersonalDocs.error.desc'));
            }

        },
        loadPersonalDocHander:async function(isZipped = false){
            let vm = this;
            if(vm.monthYearDoc){
                await vm.loadPersonalDocs(vm.monthYearDoc, isZipped);
            }
            if(!vm.monthYearDoc && isZipped){
                vm.openErrorModal(vm.$t('loadPersonalDocs.error.title'), vm.$t('loadPersonalDocs.error.empty.desc'));
            }
        }
    },
    watch: {
        "Global.locale": async function(){
          this.getProductShortLinks();
          await this.getShortLinkAndQRCode();
        },
        monthYearDoc: async function(newValue){
            if(newValue){
                await this.loadPersonalDocs(newValue);
            }
        }
    },
    computed: {
    },
};
</script>

<i18n>
{
    "en":{
        "greeting": "Hi, this is a good day!",
        "yourProfile": "Your profile",
        "viewMyDashboard": "View my dashboard",
        "toolsAndFeature": "Tools and features",
        "yourLink": "Your link",
        "copyLink": "Copy link",
        "yourQRcode": "Your QR code",
        "download": "Download",
        "getNewQuotation": "Get new quotation",
        "next": "Next",
        "tipsAndGuideline": "Tips & Guideline in 5 Minutes",
        "playVDO": "Play VDO",
        "oppReport": "Opportunity report",
        "viewReport" : "View report",
        "product": {            
            "allProduct":"All products",
            "carInsurance": "Car insurance",
            "extendedWarranty": "Extended warranty insurance",
            "motorBikeInsurance" : "Motorbike insurance", 
            "PAInsurance": "Personal accident insurance",
            "cancerInsurance": "Cancer insurance",
            "CIInsurance": "Critical illness insurance"
        },
        "warningLicenseExpire":"Your insurance broker license is almost expired. Please contact our partner hotline,02-582-8864 to submit your renewed license details",
        "expProfile":"Expires",
        "address":"Current Address",
        "myPersonalDoc":"My Documents",
        "dateLabel":"Month",
        "downloadDocButton":"Download Full Year",
        "closeDownloadDocButton":"Close",
        "loadPersonalDocs.error.title":"Download error",
        "loadPersonalDocs.error.desc":"Unable to process Download. Please try again later.",
        "emptyDocs":"No documents",
        "personalDocsHeader":"Documents",
        "loadPersonalDocs.error.empty.desc":"Unable to process download. Please select month and year."
    },
    "th":{
        "greeting": "สวัสดี",
        "yourProfile": "โปรไฟล์ของคุณ",
        "viewMyDashboard": "ดูแดชบอร์ดของฉัน",
        "toolsAndFeature": "เครื่องมือและคุณสมบัติ",
        "yourLink": "ลิงค์ของคุณ",
        "copyLink": "คัดลอกลิงค์",
        "yourQRcode": "รหัส QR ของคุณ",
        "download": "ดาวน์โหลด",
        "getNewQuotation": "ใบเสนอราคาใหม่",
        "next": "ถัดไป",
        "tipsAndGuideline": "แนะนำเคล็ดลับใน 5 นาที",
        "playVDO": "เล่นวีดีโอ",
        "oppReport": "รายงานใบเสนอราคา",
        "viewReport" : "ดูรายงาน",
        "product": {
            "allProduct":"ผลิตภัณฑ์ทั้งหมด", 
            "carInsurance": "ประกันรถยนต์",
            "extendedWarranty": "ประกันอะไหล่รถยนต์",
            "motorBikeInsurance" : "ประกันมอเตอร์ไซค์", 
            "PAInsurance": "ประกันอุบัติเหตุส่วนบุคคล",
            "cancerInsurance": "ประกันมะเร็ง",
            "CIInsurance": "ประกันโรคร้ายแรง"
        },
        "warningLicenseExpire":"บัตรนายหน้าของท่านกำลังจะหมดอายุ กรณีที่ท่านได้ทำการต่ออายุบัตรใหม่แล้ว กรุณาติดต่อที่ 02-582-8864 ขอบคุณค่ะ",
        "expProfile":"วันหมดอายุ",
        "address":"ที่อยู่ปัจจุบัน",
        "myPersonalDoc":"เอกสารของฉัน",
        "dateLabel":"เดือน",
        "downloadDocButton":"ดาวน์โหลดทั้งปี",
        "closeDownloadDocButton":"ปิด",
        "loadPersonalDocs.error.title":"เกิดข้อผิดพลาดในการโหลดเอกสาร",
        "loadPersonalDocs.error.desc":"ไม่สามารถโหลดเอกสารได้ กรุณาลองใหม่ภายหลัง",
        "emptyDocs":"ไม่มีเอกสาร",
        "personalDocsHeader":"เอกสาร",
        "loadPersonalDocs.error.empty.desc":"ไม่สามารถโหลดเอกสารได้ กรุณาเลือกปีและเดือนที่ต้องการโหลดเอกสาร"
    },
    "zh":{
        "greeting": "Hi, this is a good day!",
        "yourProfile": "Your profile",
        "viewMyDashboard": "View my dashboard",
        "toolsAndFeature": "Tools and features",
        "yourLink": "Your link",
        "copyLink": "Copy link",
        "yourQRcode": "Your QR code",
        "download": "Download",
        "getNewQuotation": "Get new quotation",
        "next": "Next",
        "tipsAndGuideline": "Tips & Guideline in 5 Minutes",
        "playVDO": "Play VDO",
        "oppReport": "机会报告",
        "viewReport" : "View report",
        "product": {            
            "allProduct":"All products",
            "carInsurance": "汽车保险",
            "extendedWarranty": "延长车辆质保保险",
            "motorBikeInsurance" : "摩托车保险", 
            "PAInsurance": "个人意外保险",
            "cancerInsurance": "癌症保险",
            "CIInsurance": "Critical illness insurance" 
        },
        "warningLicenseExpire":"Your insurance broker license is almost expired. Please contact our partner hotline,02-582-8864 to submit your renewed license details",
        "expProfile":"Expires",
        "address":"目前的地址",
        "myPersonalDoc":"My Documents",
        "dateLabel":"Month",
        "downloadDocButton":"Download Full Year",
        "closeDownloadDocButton":"Close",
        "loadPersonalDocs.error.title":"Download error",
        "loadPersonalDocs.error.desc":"Unable to process download. Please try again later.",
        "emptyDocs":"No documents",
        "personalDocsHeader":"Documents",
        "loadPersonalDocs.error.empty.desc":"Unable to process download. Please select month and year."
    }
}
</i18n>