export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, this is a good day!"])},
        "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile"])},
        "viewMyDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View my dashboard"])},
        "toolsAndFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools and features"])},
        "yourLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your link"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "yourQRcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your QR code"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips & Guideline in 5 Minutes"])},
        "playVDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play VDO"])},
        "oppReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunity report"])},
        "viewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View report"])},
        "product": {
          "allProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])},
          "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car insurance"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended warranty insurance"])},
          "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike insurance"])},
          "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal accident insurance"])},
          "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancer insurance"])},
          "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical illness insurance"])}
        },
        "warningLicenseExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your insurance broker license is almost expired. Please contact our partner hotline,02-582-8864 to submit your renewed license details"])},
        "expProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Address"])},
        "myPersonalDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Documents"])},
        "dateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
        "downloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Full Year"])},
        "closeDownloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "loadPersonalDocs.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download error"])},
        "loadPersonalDocs.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process Download. Please try again later."])},
        "emptyDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents"])},
        "personalDocsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
        "loadPersonalDocs.error.empty.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process download. Please select month and year."])}
      },
      "th": {
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวัสดี"])},
        "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรไฟล์ของคุณ"])},
        "viewMyDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูแดชบอร์ดของฉัน"])},
        "toolsAndFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เครื่องมือและคุณสมบัติ"])},
        "yourLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลิงค์ของคุณ"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดลอกลิงค์"])},
        "yourQRcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัส QR ของคุณ"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลด"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสนอราคาใหม่"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แนะนำเคล็ดลับใน 5 นาที"])},
        "playVDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เล่นวีดีโอ"])},
        "oppReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานใบเสนอราคา"])},
        "viewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดูรายงาน"])},
        "product": {
          "allProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลิตภัณฑ์ทั้งหมด"])},
          "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันรถยนต์"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอะไหล่รถยนต์"])},
          "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมอเตอร์ไซค์"])},
          "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอุบัติเหตุส่วนบุคคล"])},
          "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมะเร็ง"])},
          "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันโรคร้ายแรง"])}
        },
        "warningLicenseExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัตรนายหน้าของท่านกำลังจะหมดอายุ กรณีที่ท่านได้ทำการต่ออายุบัตรใหม่แล้ว กรุณาติดต่อที่ 02-582-8864 ขอบคุณค่ะ"])},
        "expProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันหมดอายุ"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ที่อยู่ปัจจุบัน"])},
        "myPersonalDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสารของฉัน"])},
        "dateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดือน"])},
        "downloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ดาวน์โหลดทั้งปี"])},
        "closeDownloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
        "loadPersonalDocs.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกิดข้อผิดพลาดในการโหลดเอกสาร"])},
        "loadPersonalDocs.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถโหลดเอกสารได้ กรุณาลองใหม่ภายหลัง"])},
        "emptyDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเอกสาร"])},
        "personalDocsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เอกสาร"])},
        "loadPersonalDocs.error.empty.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สามารถโหลดเอกสารได้ กรุณาเลือกปีและเดือนที่ต้องการโหลดเอกสาร"])}
      },
      "zh": {
        "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi, this is a good day!"])},
        "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile"])},
        "viewMyDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View my dashboard"])},
        "toolsAndFeature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools and features"])},
        "yourLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your link"])},
        "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])},
        "yourQRcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your QR code"])},
        "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "tipsAndGuideline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips & Guideline in 5 Minutes"])},
        "playVDO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play VDO"])},
        "oppReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机会报告"])},
        "viewReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View report"])},
        "product": {
          "allProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All products"])},
          "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽车保险"])},
          "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延长车辆质保保险"])},
          "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩托车保险"])},
          "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人意外保险"])},
          "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["癌症保险"])},
          "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical illness insurance"])}
        },
        "warningLicenseExpire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your insurance broker license is almost expired. Please contact our partner hotline,02-582-8864 to submit your renewed license details"])},
        "expProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expires"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前的地址"])},
        "myPersonalDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Documents"])},
        "dateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
        "downloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Full Year"])},
        "closeDownloadDocButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "loadPersonalDocs.error.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download error"])},
        "loadPersonalDocs.error.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process download. Please try again later."])},
        "emptyDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No documents"])},
        "personalDocsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
        "loadPersonalDocs.error.empty.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to process download. Please select month and year."])}
      }
    }
  })
}
