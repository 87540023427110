
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import badMutable from 'dayjs/plugin/badMutable';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(badMutable);
dayjs.extend(isSameOrBefore)

export default dayjs